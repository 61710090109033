import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Tooltip, Modal, Descriptions, Select, notification, Input, InputNumber, Steps  } from 'antd';
import { ANTIQUY_STATES, NEGOTIABLE_STATES, CURRENCY_TYPES, PROPERTY_STATES_FILTER, STATES_FOR_CALL } from '../../constants';
import AgenciaOnlineServices from '../../services/services';
import Utils from '../../utils/utils';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(spanishStrings)

const { TextArea } = Input;
const { Step } = Steps;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};


const FilterView = ({ reference, allPropertiesBD, allPropertiesRef }) => {
  useEffect (() => {
    if (currentStep === 2 && (infoStepTwo.price && infoStepTwo.isNegotiable && infoStepTwo.currencyType)) {
      setUpdateInfoIsCompleted(true)
      // setInitUpdateProperty(false)
    } else setUpdateInfoIsCompleted(false)
    // setTimeout(() => {
    //   if (allPropertiesBD)
    // }, 50)
  })
  let auxAllPropertiesBD = [ ...allPropertiesBD ];
  const [initUpdateProperty, setInitUpdateProperty] = useState(false);
  const [updateInfoIsCompleted, setUpdateInfoIsCompleted] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false)
  const [infoStepZero, setInfoStepZero] = useState({
    state: 1,
    address: '',
    phone: ''
  });  
  const [infoStepOne, setInfoStepOne] = useState({
    bedrooms: 0,
    completeBathrooms: 0,
    middleBathrooms: 0,
    parkings: 0,
    totalBuilded: 0,
    totalArea: 0,
    antiquy: {
      years: 0,
      type: 0
    },
    desc: ''
    
  });
  const [infoStepTwo, setInfoStepTwo] = useState({
    currencyType: 0,
    price: 0,
    isNegotiable: 0,
  }); 

  const [selectionType, setSelectionType] = useState('checkbox');
  const [showTooltip, setShowTooltip] = useState(false);
  const [propertySelected, setPropertySelected] = useState({});
  const [showModalChangeState, setShowModalChangeState] = useState(false);
  const [optionStateToUpdate, setOptionStateToUpdate] = useState(null);
  const [showEditInfo, setShowEditInfo] = useState(false)
  const [newPhone, setNewPhone] = useState("")

  const handleOption = (option) => {
    // setSelectOption(option)
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const initUpdateProp = () => {
    setInitUpdateProperty(true)
  }
  const editProperty = () => {
    setShowEditInfo(true)
    if (propertySelected && propertySelected.phone) setNewPhone(propertySelected.phone)
  }
  const updateStateByCall = () => {
    const aux = { ...propertySelected }
    const service = new AgenciaOnlineServices(allPropertiesRef)
    service.updatePropertyDB(aux.keyBD, aux).then(res => {
      openNotificationUpdateStateByCall()
    },
      error => {
        openNotificationUpdateFailed()
        console.log(error)
      })
  }
  
  const hiddenShowEditInfo = () => {
    setShowEditInfo(false)
    setNewPhone("")
  }

  const updateInfoUpdated = () => {
    let aux = { ...propertySelected }
    if (propertySelected.phone !== newPhone) {
      const service = new AgenciaOnlineServices(allPropertiesRef)
      aux.phone = newPhone
      service.updatePropertyDB(aux.keyBD, aux).then(res => {
        console.log(res)
        openNotificationUpdate()
        setShowEditInfo(false)
        },
          error => {
            openNotificationUpdateFailed()
            console.log(error)
          })
    } else
      alert("Nuevo número de telefono es igual al anterior")
  }

  const openNotification = () => {
    notification.success({
      message: `Actualización de inmueble`,
      description:
        'La propiedad fue actualizada con éxito',
        duration: 2
    });
  };
  const openNotificationUpdate = () => {
    notification.success({
      message: `Actualización de inmueble`,
      description:
        'El número de teléfono fue actualizado con éxito',
        duration: 2
    });
  };
  const openNotificationUpdateFailed = () => {
    notification.error({
      message: `Actualización de inmueble`,
      description:
        'El número de teléfono no fue actualizado con éxito',
        duration: 2
    });
  };
  const openNotificationUpdateStateByCall = () => {
    notification.success({
      message: `Actualización de inmueble`,
      description:
        'El estado de llamada fue actualizado con éxito',
        duration: 2
    });
  };
  const selectPropertyToChangeState = (obj) => {
    setPropertySelected(obj)
    setShowModalChangeState(true)
  }

  const closeModalChangeState = () => {
    setShowModalChangeState(false)
    setInitUpdateProperty(false)
    setShowEditInfo(false)
    // if (propertySelected.stateByCall > 0)
    setInfoStepZero({
      state: 1,
      address: '',
      phone: ''
    });  
    setInfoStepOne({
      bedrooms: 0,
      completeBathrooms: 0,
      middleBathrooms: 0,
      parkings: 0,
      totalBuilded: 0,
      totalArea: 0,
      antiquy: {
        years: 0,
        type: 0
      },
      desc: ''      
    });
    setInfoStepTwo({
      currencyType: 0,
      price: 0,
      isNegotiable: 0,
    }); 
  }

  const updateProperty = () => {
    setIsUpdating(true)
    let aux = { ...propertySelected }
    propertySelected.stateProperty= null
    propertySelected.stateProperty= optionStateToUpdate;
    const service = new AgenciaOnlineServices(allPropertiesRef)
    aux.stateProperty = infoStepZero.state
    aux.information = {
      address: infoStepZero,
      features: infoStepOne,
      price: infoStepTwo
    }
    if (aux.information && aux.information.address && aux.information.address.state) delete aux.information.address.state
    if (aux.information && aux.information.features && aux.information.features) {
      delete aux.information.features["antiquy.type"]
      delete aux.information.features["antiquy.years"]
    }
    service.updatePropertyDB(aux.keyBD, aux).then(res => {
      setIsUpdating(false)
        closeModalChangeState(false)
        openNotification()
        setInitUpdateProperty(false)
        setCurrentStep(0)
        setInfoStepZero({
          state: 1,
          address: '',
          phone: ''
        })
        setInfoStepOne({
          bedrooms: 0,
          completeBathrooms: 0,
          middleBathrooms: 0,
          parkings: 0,
          totalBuilded: 0,
          totalArea: 0,
          antiquy: {
            years: 0,
            type: 0
          },
          desc: ''
          
        })
        setInfoStepTwo({
          currencyType: 0,
          price: 0,
          isNegotiable: 0,
        })
      },
        error => {
          openNotificationUpdateFailed()
          console.log(error)
        })
  }
  const changeNameType = (type) => {
    let result = ""
      switch (type) {
        case 1: return result = 'Terreno'
        case 2: return result = 'Casa'
        case 3: return result = 'Departamento'
        case 4: return result = 'Local'
      }
    return result;
  }
  const handleOptionState = (option) => {
    setOptionStateToUpdate(option)
  }
  const onFinish = () => {

  }
  
  const onFinishFailed = () => {
    
  }
  const nextStep = () => {
    if (currentStep === 2) {
      if((infoStepOne.antiquy > 0 && infoStepOne.bedrooms > 0 && infoStepOne.completeBathrooms > 0 &&
        infoStepOne.middleBathrooms > 0 && infoStepOne.parkings > 0 &&
        infoStepOne.totalArea > 0 && infoStepOne.totalBuilded > 0) && 
        (infoStepZero.state != 1 && infoStepZero.address)) {
        }
    }
    if (currentStep === 0) {
      if (infoStepZero.state != 1 && infoStepZero.address) return setCurrentStep(1)
    }
    if (currentStep === 1) {
      if (propertySelected.propertyType === 1) {
        return setCurrentStep(2)
      } else {        
      if(infoStepOne.antiquy && infoStepOne.bedrooms > 0 && infoStepOne.completeBathrooms > 0 &&
        infoStepOne.middleBathrooms >= 0 && infoStepOne.parkings >= 0 &&
        infoStepOne.totalArea > 0 && infoStepOne.totalBuilded > 0) {
          if ((infoStepOne.antiquy.type === 2 && infoStepOne.antiquy.years > 0) || infoStepOne.antiquy.type !== 2) return setCurrentStep(2)
        }
      }
    }
  }
  const backStep = () => {
    if (currentStep === 2) setCurrentStep(1)
    if (currentStep === 1) setCurrentStep(0)
  }
  const handleValue = (evt) => {
    let aux = {...infoStepZero}
    if (typeof evt === "number") {
      aux.state = Number(evt)
      return setInfoStepZero(aux)
    }
    if (evt && evt.target) {
      if (evt.target.id === "newphone") {
        return setNewPhone(evt.target.value)
      }
      aux[evt.target.id] = evt.target.value
      setInfoStepZero(aux)
    }
  }
  const handleStateByCall = (evt) => {
    let aux = {...propertySelected}
    if (evt && evt) {
      aux.stateByCall = evt
      setPropertySelected(aux)
      // updateStateByCall(aux)
    }

  }
  const handleValueStepOne = (evt, id) => {
    let aux = {...infoStepOne}
    if (id) {
      if ((id.split(".").length > 1)) {
        const valuesAux = id.split(".")
        aux[valuesAux[0]][valuesAux[1]] = evt
        // setInfoStepOne(aux)
        if ((evt === 1) || (evt === 3))  {
          aux.antiquy.years = 0
          // setShowYears(false)
          setInfoStepOne(aux)
        }
        // if (evt === 2) setShowYears(true)
        
        // return console.log("escogio antiguedad")
      }
      aux[id] = evt;
      setInfoStepOne(aux)
    } 
    if (typeof evt === "number" && id === undefined) {
      aux.antiquy = Number(evt)
      setInfoStepOne(aux)
    }
    if (evt && evt.target) {
      aux[evt.target.id] = evt.target.value
      setInfoStepOne(aux)
    }
  }
  const handleValueStepTwo = (evt, id) => {
    let aux = {...infoStepTwo}
    if (id) {
      aux[id] = evt;
      setInfoStepTwo(aux)
    } 
    if (typeof evt === "number" && id === undefined) {
      aux.antiquy = Number(evt)
      setInfoStepTwo(aux)
    }
    if (evt && evt.target) {
      aux[evt.target.id] = evt.target.value
      setInfoStepTwo(aux)
    }
  }
  auxAllPropertiesBD = auxAllPropertiesBD && auxAllPropertiesBD.map(e => {
    if (e.stateProperty != 2 && e.stateProperty != 3) return e
  })
  auxAllPropertiesBD = auxAllPropertiesBD.reverse()
  let acumLands = []
  let acumHomes = []
  let acumApartments = []
  let acumOffices = []
  let acumForUpdate = []
  let acumWithProp = []
  let acumWithOtherAgent = []
  
  if(allPropertiesBD && allPropertiesBD.length) {
    allPropertiesBD.forEach(e => {
      if (e.stateProperty === 1) acumForUpdate.push(e)
      if (e.stateProperty === 2) acumWithProp.push(e)
      if (e.stateProperty === 3) acumWithOtherAgent.push(e)

      if (e.propertyType === 1) acumLands.push(e)
      if (e.propertyType === 2) acumHomes.push(e)
      if (e.propertyType === 3) acumApartments.push(e)
      if (e.propertyType === 4) acumOffices.push(e)      
    })
  }
  
  return (
    <div className="real-state">
    <React.Fragment className="real-state">
    <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }} style={{textAlign: "left"}}>
          <Col>
              <h5>(*) Existen {allPropertiesBD && allPropertiesBD.length} propiedades en total</h5>
              <h5>(*) Existen {acumForUpdate && acumForUpdate.length} propiedades por actualizar</h5>
              <h5>(*) Existen {acumWithProp && acumWithProp.length} propiedades con propietarios</h5>
              <h5>(*) Existen {acumWithOtherAgent && acumWithOtherAgent.length} propiedades con agentes inmobiliarios</h5>
           </Col>
           <Col>
            <h5>(*) Existen {acumLands && acumLands.length} Terrenos </h5>
            <h5>(*) Existen {acumHomes && acumHomes.length} casas </h5>
            <h5>(*) Existen {acumApartments && acumApartments.length} departamentos </h5>
            <h5>(*) Existen {acumOffices && acumOffices.length} locales </h5>
           </Col>
      </Row>
      <br />
      <hr style={{border: '1px solid black'}}/>
      <br />
      <h4>TABLA DE INMUEBLES A FILTRAR</h4>
      {
        auxAllPropertiesBD &&
        // <Tooltip placement="left" visible={(showTooltip && !showModalChangeState)} title="Dale click a cualquier propiedad de la tabla para cambiar de estado">
      <table id="table-all-filters">
        <thead>
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Tipo de propiedad</th>
            <th scope="col">Distrito</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Estado actual</th>
            <th scope="col">Hora de registro</th>
          </tr>
        </thead>
        <tbody>
          {
            auxAllPropertiesBD && auxAllPropertiesBD.map(e => {
              if (e) {
                return (
                  <tr className={(e && e.stateProperty === 1 && e.stateByCall === 0) ? "property-pending" : (e && e.stateProperty === 1 && e.stateByCall !== 0) ? 'property-problem' : null} onClick={() => selectPropertyToChangeState(e)} 
                  // onMouseOver={() => setShowTooltip(true)} onMouseOut={() => setShowTooltip(false)}
                  >
                    {e.id && <td>{e.id}</td>}
                    <td data-label="Tipo de propiedad">{e.propertyType && Utils.getNameTypePropertyByCode(e.propertyType)}</td>
                    <td data-label="Distrito">{e.distric}</td>
                    <td data-label="Teléfono">{e.phone}</td>
                    <td data-label="Estado actual">{e.stateProperty && Utils.getNameStateByCode(e.stateProperty)} {(e.stateByCall != 0 && `- ${Utils.getNameStateByCall(e.stateByCall)}`)}</td>
                    {/* {e.dateRegistered && <td data-label="Estado actual">{e.dateRegistered && Utils.getNameStateByCode(e.stateProperty)}</td>} */}
                    <td data-label="Hora de registro">
                    <TimeAgo date={e.dateRegistered} formatter={formatter} />
                    </td>
                  </tr>
                )
              }           
            })
          }
        </tbody>
      </table>
      }
      <Modal visible={showModalChangeState} onOk={() => updateProperty()} onCancel={() => closeModalChangeState()} width={900} cancelText="CERRAR" okText="ACTUALIZAR" style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} className="modal-update-property"
            >
              <React.Fragment>
              {
                propertySelected &&
                <Descriptions
                  title={`Actualizar información de la propiedad ubicada en ${propertySelected.distric}`}
                  bordered
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label="Código">{propertySelected.id}</Descriptions.Item>
                  <Descriptions.Item label="Teléfono" >{propertySelected.phone}</Descriptions.Item>
                  <Descriptions.Item label="Estado">{propertySelected.stateProperty && Utils.getNameStateByCode(propertySelected.stateProperty)}</Descriptions.Item>
                  
                  <Descriptions.Item label="Tipo de inmueble">                    
                    {changeNameType(propertySelected.propertyType)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Problema en llamada">
                      <Select placeholder="Selecciona" style={{ width: 170 }} disabled={initUpdateProperty || propertySelected && propertySelected.stateProperty > 1} id="stateByCall" value={propertySelected.stateByCall > 0 ? propertySelected.stateByCall : "Seleccione"} onChange={(value) => handleStateByCall(value)}>
                          {
                          STATES_FOR_CALL && STATES_FOR_CALL.map(e => { 
                          return <Select.Option value={e.value}>{e.label}</Select.Option>
                          })
                        }
                      </Select>
                  </Descriptions.Item>
                </Descriptions>
              }
              <br />
              {
                (propertySelected.stateByCall > 0 && !initUpdateProperty) &&
                <div>
                <Button type="primary" onClick={() => updateStateByCall()}>
                GUARDAR ESTADO DE LLAMADA
              </Button>
                  <br />
                </div>
              }
              <br />
              {
                (!initUpdateProperty && propertySelected && propertySelected.stateProperty === 1 && !showEditInfo && propertySelected.stateByCall !== 2) && 
                  <Button type="dashed" danger onClick={() => initUpdateProp()}>
                  INICIAR ACTUALIZACION
                </Button>
              }
              {
                (!initUpdateProperty && propertySelected && propertySelected.stateProperty === 1 && !showEditInfo) && 
                  <Button type="dashed" danger onClick={() => editProperty()}>
                  EDITAR
                </Button>
              }
              {
                propertySelected && propertySelected.stateProperty > 1 &&
                <h3>Propiedad actualizada con el estado {propertySelected.stateProperty && Utils.getNameStateByCode(propertySelected.stateProperty)}</h3>
              }
              {
                (showEditInfo && !initUpdateProperty) &&
                <div>
                <br />
                  <label>Nuevo teléfono: </label>
                <Input style={{ width: 100 }} id="newphone" onChange={(evt) => handleValue(evt)}  value={newPhone}/>
                <br />
                <br />
                <Button type="primary" onClick={() => updateInfoUpdated()} className="btn-next"  size="large">
                ACTUALIZAR
              </Button>
              <br />
              <br />
              <Button type="primary" onClick={() => hiddenShowEditInfo()} className="btn-next"  size="large">
                CANCELAR
              </Button>
                </div>
              }
             {
             !initUpdateProperty && 
             <div>
               <br />
              <br />
               </div>
               }
              {
                initUpdateProperty &&
                <Steps size="small" current={currentStep}>
                 <Step title="Ubicación" />
                 <Step title="Características" />
                 <Step title="Precios" />
               </Steps>
              }
              {
                (initUpdateProperty && currentStep === 0) ?
                <div id="step0">
                <table>
                  <tbody>
                    <tr>
                      <td>Contacto con</td>
                      <td>
                        <Select placeholder="Selecciona" style={{ width: 170 }} id="state" value={infoStepZero.state} onChange={(value) => handleValue(value)}>
                              {
                              PROPERTY_STATES_FILTER && PROPERTY_STATES_FILTER.map(e => { 
                              if (e.value !== 4 && e.value !== 5 && e.value !== 6) return <Select.Option value={e.value}>{e.label}</Select.Option>
                              })
                            }
                          </Select>
                      </td>
                    </tr>
                    <tr>
                      <td>Dirección exacta</td>
                      <td>
                      <Input style={{ width: 300 }} id="address" onChange={(evt) => handleValue(evt)} value={infoStepZero.address} />
                      </td>
                    </tr>
                    <tr>
                      <td>Teléfono de contacto (Opcional)</td>
                      <td>
                      <Input style={{ width: 100 }} id="phone" onChange={(evt) => handleValue(evt)}  value={infoStepZero.phone}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> : null
              }
              {
                (initUpdateProperty && currentStep === 1) ?
                <div id="step1">
                <table>
                  <tbody>
                    <tr>
                      <td>Dormitorios</td>
                      <td>
                        <InputNumber min={1} min={1} onChange={(evt) => handleValueStepOne(evt, "bedrooms")} value={infoStepOne.bedrooms} id="bedrooms" />
                      </td>
                    </tr>
                    <tr>
                      <td>Baños completos</td>
                      <td>
                        <InputNumber min={1} value={infoStepOne.completeBathrooms} id="completeBathrooms" onChange={(evt) => handleValueStepOne(evt, "completeBathrooms")}/>
                      </td>
                    </tr>
                    <tr>
                      <td>Baños medios</td>
                      <td>
                        <InputNumber min={0} value={infoStepOne.middleBathrooms} id="middleBathrooms" onChange={(evt) => handleValueStepOne(evt, "middleBathrooms")} />
                      </td>
                    </tr>
                    <tr>
                      <td>Estacionamientos</td>
                      <td>
                        <InputNumber min={0} value={infoStepOne.parkings} id="parkings" onChange={(evt) => handleValueStepOne(evt, "parkings")} />
                      </td>
                    </tr>
                    <tr>
                      <td>Área construida (m2)</td>
                      <td>
                        <InputNumber min={1} value={infoStepOne.totalBuilded} id="totalBuilded" onChange={(evt) => handleValueStepOne(evt, "totalBuilded")} />
                      </td>
                    </tr>
                    <tr>
                      <td>Área total (m2)</td>
                      <td>
                        <InputNumber min={1} value={infoStepOne.totalArea} id="totalArea" onChange={(evt) => handleValueStepOne(evt, "totalArea")} />
                      </td>
                    </tr>
                    <tr>
                      <td>Antigüedad</td>
                      <td>
                        <Select 
                        placeholder="Selecciona" 
                        style={{ width: 160 }} 
                        id="antiquy" value={infoStepOne.antiquy.type > 0 ? infoStepOne.antiquy.type : 'Selecciona'} onChange={(evt) => handleValueStepOne(evt, "antiquy.type")}>
                              {
                              ANTIQUY_STATES && ANTIQUY_STATES.map(e => <Select.Option value={e.value}>{e.label}</Select.Option>)
                            }
                          </Select>
                          {
                            (infoStepOne.antiquy && infoStepOne.antiquy.type === 2) && <InputNumber min={1} value={infoStepOne.antiquy.years} id="antiquy" onChange={(evt) => handleValueStepOne(evt, "antiquy.years")} />
                          }
                      </td>
                    </tr>
                    <tr>
                      <td>Anotación (Opcional)</td>
                      <td>
                      <TextArea rows={4}  style={{ width: 250 }} id="desc" value={infoStepOne.desc} onChange={(evt) => handleValueStepOne(evt)}/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> : null
              }
              {
                (initUpdateProperty && currentStep) === 2 ?
                <div id="step1">
                <table>
                  <tbody>
                    <tr>
                      <td>Precio</td>
                      <td>
                          <Select placeholder="Selecciona moneda" style={{ width: 180 }} id="currencyType" value={infoStepTwo.currencyType > 0 ? infoStepTwo.currencyType : 'Selecciona moneda'} onChange={(evt) => handleValueStepTwo(evt, "currencyType")}>
                              {
                              CURRENCY_TYPES && CURRENCY_TYPES.map(e => <Select.Option value={e.value}>{e.label}</Select.Option>)
                              }
                          </Select>                    
                        <InputNumber min={1} value={infoStepTwo.price} id="price" onChange={(evt) => handleValueStepTwo(evt, "price")} disabled={infoStepTwo.currencyType === 0} />
                      </td>
                    </tr>
                    <tr>
                      <td>Negociable</td>
                      <td>
                        <Select placeholder="Selecciona" style={{ width: 160 }} id="isNegotiable" value={infoStepTwo.isNegotiable > 0 ? infoStepTwo.isNegotiable : 'Selecciona'} onChange={(evt) => handleValueStepTwo(evt, "isNegotiable")}>
                              {
                              NEGOTIABLE_STATES && NEGOTIABLE_STATES.map(e => <Select.Option value={e.value}>{e.label}</Select.Option>)
                            }
                          </Select>
                      </td>
                    </tr>
                    </tbody>
                    </table>
                    </div> : null
              }
                 {
                (initUpdateProperty && currentStep > 0) && 
                <Button type="primary" onClick={() => backStep()} className="btn-back" icon={<ArrowLeftOutlined />} size="large" >
                ATRAS
              </Button>
              }
              {
                ((currentStep === 0 && initUpdateProperty) || (currentStep === 1 && initUpdateProperty )) && 
                <Button type="primary" onClick={() => nextStep()} className="btn-next" icon={<ArrowRightOutlined />} size="large">
                SIGUIENTE
              </Button>
              }
              {
                updateInfoIsCompleted &&
                <Button type="primary" onClick={() => updateProperty()} className="btn-next"  size="large" disabled={isUpdating}>
                {
                  isUpdating ?
                  "ACTUALIZANDO"
                  : "ACTUALIZAR"
                }
              </Button>
              }
            </React.Fragment>
            </Modal>
    </React.Fragment>
    </div>
  )
}

export default FilterView;
