export const PROPERTIES_REAL_ESTATE = [
  {
    id: '000001'
  }
]

export const DISTRICTS = [
  "CERCADO DE LIMA",
  "ANCON",
  "ATE",
  "BARRANCO",
  "BREÑA",
  "CARABAYLLO",
  "CHORRILLOS",
  "CIENEGUILLA",
  "COMAS",
  "EL AGUSTINO",
  "INDEPENDENCIA",
  "JESUS MARIA",
  "LA MOLINA",
  "LA VICTORIA",
  "LINCE",
  "LOS OLIVOS",
  "CHOSICA",
  "LURIN",
  "MAGDALENA DEL MAR",
  "PUEBLO LIBRE",
  "MIRAFLORES",
  "PACHACAMAC",
  "PUCUSANA",
  "PUNTA HERMOSA",
  "PUENTE PIEDRA",
  "PUNTA NEGRA",
  "RIMAC",
  "SAN BARTOLO",
  "SAN BORJA",
  "SAN ISIDRO",
  "SAN JUAN DE LURIGANCHO",
  "SAN JUAN DE MIRAFLORES",
  "SAN LUIS",
  "SAN MARTIN DE PORRES",
  "SAN MIGUEL",
  "SANTA ANITA",
  "SANTA MARIA DEL MAR",
  "SANTA ROSA",
  "SANTIAGO DE SURCO",
  "SURQUILLO",
  "VILLA EL SALVADOR",
  "VILLA MARIA DEL TRIUNFO"
]

export const USER_ADMIN = {
  username: 'eddev92',
  password: 'llancaharo1'
}

export const PROPERTY_STATES = [
  {
    label: 'Por actualizar estado',
    value: 0
  },
  {
    label: 'Con agente',
    value: 1
  },
  {
    label: 'Disponible',
    value: 2
  }
]

export const USER_AUTH = [
  {
    id: "e.llanca",
    password: "llancaharo1",
    token: "123"
  },
  {
    id: "p.mayorga",
    password: "pedro",
    token: "5555"
  },
  {
    id: "j.cieza",
    password: "joaquin",
    token: "123456789"
  },
  {
    id: "c.portillo",
    password: "cristina",
    token: "3333"
  },
  {
    id: "i.calderon",
    password: "igor",
    token: "7777"
  },
  {
    id: "biker",
    password: "biker",
    token: "8888"
  },
  {
    id: "t.moro",
    password: "tulio",
    token: "987654"
  }
]

export const USERS_ACTIVES = [
  {
    idUser: "000001",
    fullNames: "Edward",
    fullLastNames: 'Llanca',
    phone: "994381708",
    documentId: "47572293",
    email: "edward.llanca@starscorporation.pe",
    role: 1,
    token: "123"
  },
  {
    idUser: "000002",
    fullNames: "Joaquín",
    fullLastNames: 'Cieza',
    phone: "12345",
    documentId: "12345",
    email: "callcenter@callcenter.com",
    role: 2,
    token: "123456789"
  },
  {
    idUser: "000003",
    fullNames: "Rutero",
    fullLastNames: 'Biker',
    phone: "12345",
    documentId: "12345",
    email: "ciclista@rutero.com",
    role: 3,
    token: "8888"
  },
  {
    idUser: "000004",
    fullNames: "Pedro",
    fullLastNames: 'Mayorga',
    phone: "952317684",
    documentId: "41175239",
    email: "pmayorgaa@hotmail.com",
    role: 4,
    token: "5555"
  },
  {
    idUser: "000005",
    fullNames: "Cristina Margot",
    fullLastNames: 'Portillo Vento',
    phone: "983518090",
    documentId: "09433349",
    email: "cportivento@gmail.com",
    role: 4,
    token: "3333"
  },
  {
    idUser: "000006",
    fullNames: "Igor",
    fullLastNames: 'Calderón Diaz',
    phone: "948520463",
    documentId: "10025503",
    email: "icalderondiaz@gmail.com",
    role: 4,
    token: "7777"
  },
  {
    idUser: "000007",
    fullNames: "Tulio",
    fullLastNames: 'Moro',
    phone: "997569774",
    documentId: "09301709",
    email: "tullio.moro@gmail.com",
    role: 4,
    token: "987654"
  }
]

export const ROLES_LABEL = {
  admin: 'Administrador',
  biker: 'Rider',
  preFilter: 'Agente pre-filtrado',
  callCenter: 'Agente call center',
  close: 'Agente inmobiliario'
}

export const ROLES = {
  admin: {
    showAddProperty: true,
    showPreFilter: true,
    showCallCenter: true,
    showClose: true,
    showAllProperties: true
  },
  callcenter: {
    showAddProperty: false,
    showPreFilter: false,
    showCallCenter: true,
    showClose: false,
    showAllProperties: true
  },
  biker: {
    showAddProperty: true,
    showPreFilter: false,
    showCallCenter: false,
    showClose: false,
    showAllProperties: false
  },
  agent: {
    showAddProperty: true,
    showPreFilter: true,
    showCallCenter: true,
    showClose: true,
    showAllProperties: true
  },
  preFilter: {
    showAddProperty: false,
    showPreFilter: false,
    showCallCenter: true,
    showClose: false,
    showAllProperties: true
  }
}

export const PROPERTY_STATES_FILTER = [
  {
    label: 'Por actualizar estado',
    value: 1
  },
  {
    label: 'PROPIETARIO',
    value: 2
  },
  {
    label: 'AGENTE INMOBILIARIO',
    value: 3
  },
  {
    label: 'Lista para vender',
    value: 4
  },
  {
    label: 'Propiedad cancelada',
    value: 5
  },
  {
    label: 'Propiedad para después',
    value: 6
  },
  {
    label: 'UN TERCERO',
    value: 7
  }
]
export const STATES_FOR_CALL = [
  {
    label: 'Número no responde',
    value: 1
  },
  {
    label: 'Propiedad vendida',
    value: 2
  },
  {
    label: 'Número equivocado',
    value: 3
  },
  {
    label: 'Llamar luego',
    value: 4
  }
]
export const ANTIQUY_STATES = [
  {
    label: 'A estrenar',
    value: 1
  },
  {
    label: 'Años de antigüedad',
    value: 2
  },
  {
    label: 'En construcción',
    value: 3
  }
]

export const NEGOTIABLE_STATES = [
  {
    label: 'Si es negociable',
    value: 1
  },
  {
    label: 'No negociable',
    value: 2
  }
]

export const CURRENCY_TYPES = [
  {
    label: 'Soles',
    value: 1
  },
  {
    label: 'Dólares',
    value: 2
  }
]

export const PROPERTY_STATE_BY_CLIENT_FILTER = [
  {
    label: 'Sin estado',
    value: '0'
  },
  {
    label: 'Acepta contrato',
    value: '1'
  },
  {
    label: 'Cliente no desea',
    value: '2'
  }
]

export const PROPERTY_TYPE = [
  {
    label: 'Terreno',
    value: 1
  },
  {
    label: 'Casa',
    value: 2
  },
  {
    label: 'Departamento',
    value: 3
  },
  {
    label: 'Local',
    value: 4
  }
]