import React, { useState, useEffect } from 'react';
import './../styles/dashboard.css'
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined
} from '@ant-design/icons';
import RealEstatesView from './real-estates';
import FilterView from './filter';
import AllPropertiesView from './allProperties';
import CallCenterView from './call-center';
import CloseView from './close';
import ForLateView from './for-late';
import Utils from '../../utils/utils';
import { ROLES } from '../../constants';

const { Header, Sider, Content, Footer } = Layout;
let clientsAux = [];

const Dashboard = ({ reference, allPropertiesRef, propsAux, returnToken }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [mainOption, setMainOption] = useState("1")
  const [allPropertiesBD, setAllPropertiesBD] = useState([])
  const token = localStorage.getItem("token") || ''
  const [userActive, setUserActive] = useState({})
  const [menuActive, setMenuActive] = useState(null)
  let user = {}
  const auxFooter = document.querySelectorAll(".home-init")
  useEffect(() => {
    setTimeout(() => {
      if (!token) propsAux.history.push("/")
      const aux = document.querySelectorAll(".login-notification")
      if (aux && aux[0]) {
          aux[0].remove()
      }
      if (token) {
        returnToken(token)
        user = Utils.getUserByToken(token)
      }
      if (token && auxFooter && auxFooter[0]) {
        auxFooter[0].style.display = "none"
      }
      if (user) {
        setUserActive(user)
        if (allPropertiesBD.length === 0) return getAllPropertiesFirebase()
      }
    }, 50)

  })

  const getAllPropertiesFirebase = () => {
    let allIds = []
    let propertiesAux = []
    allPropertiesRef.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
          snapshot.forEach(e => {
            allIds.push(e.key)
          })
           propertiesAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           propertiesAux = Object.values(propertiesAux)
           propertiesAux = propertiesAux.map((e, index) => {
            if (allIds.length) {
            allIds.forEach(k => {
              e.keyBD = `IDPROP${allIds[index]}`
            })
            }
            return e
          })
           setAllPropertiesBD(propertiesAux)
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }

    const toggle = () => {
        setCollapsed(!collapsed)
    };
    const selectOption = (option) => {
      if (option === "5" || option === "2" || option === "3" || option === "4" || option === "6") getAllPropertiesFirebase()
      setMainOption(option)
    }
    
    const logout = () => {
      propsAux.history.push('/')
      localStorage.clear()
      auxFooter[0].style.display = "block"
    }
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <a>Perfil</a>
        </Menu.Item>
        <Menu.Item key="1" onClick={() => logout()}>
          <a >Salir</a>
        </Menu.Item>
        <Menu.Divider />
      </Menu>
    );
    let userWithRoles = { ...userActive }
    if (userActive.role) {
      userWithRoles.permissions = Utils.assignedRoles(userActive.role)
      userWithRoles.roleLabel = Utils.getRoleLabelByCode(userActive.role)
    }
    const initiaView = [Utils.validateViewInitialByRole(userActive.role)]
    // const updateMenuActive = () => {
    //   const result = Utils.validateViewInitialByRole(userActive.role)
    //   console.log(initiaView)
    //   return initiaView.push(result)
    // }
    // const
    return (
      <Layout>
      <Sider 
      trigger={null} collapsible collapsed={collapsed}
        width={170}
       style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      >
        <div className="logo">
        {/* {!collapsed && <img src="./images/logo-oficial.png" className="img-logo" />} */}
        <span class="fa fa-home" style={{'font-size': '4em', margin: 'auto'}}></span>
        </div>
          <Menu theme="dark" mode="inline" selectedKeys={(initiaView.length && (Number(initiaView[0]) < mainOption)) ? [mainOption] : initiaView} className="menu-dashboard-inmobiliaria">
          {
            (userWithRoles && (userWithRoles.role !== 2)) &&
            <Menu.Item key="1" icon={<UserOutlined />} onClick={() => selectOption("1")}>
            AGREGAR
          </Menu.Item>
          }
          {
            (userWithRoles && (userWithRoles.role === 1 || userWithRoles.role === 2 || userWithRoles.role === 4)) &&
            <Menu.Item key="2" icon={<VideoCameraOutlined />} onClick={() => selectOption("2")}>
              FILTRAR
            </Menu.Item>
          }          
          {
              (userWithRoles && (userWithRoles.role === 1 || userWithRoles.role === 2 || userWithRoles.role === 4)) &&
            <Menu.Item key="3" icon={<UploadOutlined />} onClick={() => selectOption("3")}>
              CALL CENTER
            </Menu.Item>
          }          
          {
              (userWithRoles && (userWithRoles.role === 1 || userWithRoles.role === 4)) &&
            <Menu.Item key="4" icon={<UploadOutlined />} onClick={() => selectOption("4")}>
              CIERRE
            </Menu.Item>
          }
          {
              (userWithRoles && (userWithRoles.role === 1 || userWithRoles.role === 4)) &&
            <Menu.Item key="5" icon={<UploadOutlined />} onClick={() => selectOption("5")}>
              PROPIEDADES
            </Menu.Item>
          }
          
          {
              (userWithRoles && (userWithRoles.role === 1 || userWithRoles.role === 2 || userWithRoles.role === 4)) &&
            <Menu.Item key="6" icon={<UploadOutlined />} onClick={() => selectOption("6")}>
              PARA DESPUES
            </Menu.Item>
          }
          <Menu.Item danger={false}
          icon={
            <a onClick={toggle}>
            {
              collapsed ?            
            <DoubleRightOutlined style={{ marginLeft: (collapsed) ? 90 : 10, color: "white", fontSize: "1.8em", marginTop: "8px" }}/>
            :
            <DoubleLeftOutlined style={{ marginLeft: (collapsed) ? 90 : 10, color: "white", fontSize: "1.8em", marginTop: "8px" }}/>
            }
        </a>
          }
            >           
            {/* {collapsed && "EXPANDIR"} */}
          </Menu.Item>
        </Menu>
        
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: (collapsed) ? 0 : 150 }}>
       <Header className="site-layout-background header-name" style={{ padding: 0 }} >
                <Dropdown overlay={menu} trigger={['click']}>
                  <div className="user-label" onClick={e => e.preventDefault()} style={{cursor: 'pointer'}}>
                    <Avatar size="large" icon={<UserOutlined />} />
                    <h5>Hola!, {(userActive.role === 1) ? "Administrador" : (userActive && userActive.fullNames) && userActive.fullNames} </h5>
                  </div>
                </Dropdown>
        </Header>
        <Content style={{ margin: `24px ${(collapsed) ? '85px' : '16px'} 0`, overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: 24, textAlign: 'center', maxHeight: "420px", overflowY: "auto" }}>
          {
            ((initiaView.length && initiaView[0] === '1' && mainOption === '1'))
            && <RealEstatesView 
            // getAllPropertiesFirebase={getAllPropertiesFirebase()}
            reference={reference} allPropertiesRef={allPropertiesRef} propsAux={propsAux} allPropertiesBD={allPropertiesBD}/>
          }
          {
            (((initiaView.length && initiaView[0] <= '2' && mainOption === '2') || (initiaView.length && initiaView[0] > '3' && mainOption !== '3') || (initiaView.length && initiaView[0] > '3' && mainOption !== "6") 
             || (initiaView.length && initiaView[0] <= '2' && mainOption === "1") &&  (initiaView.length && initiaView[0] !== '1' && mainOption !== "1") || 
             ((initiaView.length && initiaView[0] <= '2' && mainOption === '2') || (initiaView.length && initiaView[0] > '3' && mainOption !== '3') || (initiaView.length && initiaView[0] > '3' && mainOption !== "6") 
             || (initiaView.length && initiaView[0] <= '2' && mainOption === "1") &&  (initiaView.length && initiaView[0] === '2' && mainOption === "1"))
             )
            )
            && <FilterView  allPropertiesBD={allPropertiesBD} allPropertiesRef={reference} />
          }
          {
           mainOption === '3' 
            && <CallCenterView allPropertiesBD={allPropertiesBD} allPropertiesRef={reference} />
          }
          {
            mainOption === "4" 
            && <CloseView allPropertiesBD={allPropertiesBD} allPropertiesRef={reference}  />
          }
          {
            mainOption === "5" 
            && <AllPropertiesView allPropertiesBD={allPropertiesBD} />
          }
          {
            mainOption === "6" 
            && <ForLateView allPropertiesBD={allPropertiesBD} />
          }
        </div>
        </Content>
        <Footer style={{ textAlign: 'center', padding: 0 }}>
        <div class="home-footer">
          <div class="home-wrapper">
              <div class="copyright">
                  <p class="copyright-text">Copyright &copy; 2020 - 2021 Todos los derechos reservados por 
                  <a href="https://starscorporation.pe/" target="_blank"> Stars Corporation</a>
                      </p>
                  </div>
          </div>
      </div>
        </Footer>
      </Layout>
    </Layout>
    
    )
  // }
}

export default Dashboard;
