import { ROLES, USERS_ACTIVES } from "../constants";

class Utils {
  static getNameStateByCode(code) {
    let result = ''
    if (code) {
      switch(Number(code)) {
        case 1: return result = "Por actualizar estado";
        case 2: return result = "Propietario";
        case 3: return result = "Agente inmobiliario";
        case 4: return result = "Lista para vender";
        case 5: return result = "Propiedad cancelada";
        case 6: return result = "Para después";
        case 7: return result = 'Respondió un tercero';
      }
    }
    return result;
  }
  
  static getNameTypePropertyByCode(code) {
    let result = ''
    if (code) {
      switch(Number(code)) {
        case 1: return result = "Terreno";
        case 2: return result = "Casa";
        case 3: return result = "Departamento";
        case 4: return result = "Local";
      }
    }
    return result;
  }

  static getNameStateByCall(code) {
    let result = ''
    if (code) {
      switch(Number(code)) {
        case 1: return result = "Número no responde";
        case 2: return result = "Propiedad vendida";
        case 3: return result = "Número equivocado";
        case 4: return result = "Llamar luego";
      }
    }
    return result;
  }

  static changeNameType(type) {
    let result = ""
      switch (type) {
        case 1: return result = 'Terreno'
        case 2: return result = 'Casa'
        case 3: return result = 'Departamento'
        case 4: return result = 'Local'
      }
    return result;
  }
  static changeStatebyType(type) {
    let result = ""
      switch (type) {
        case 1: return result = 'Por actualizar estado'
        case 2: return result = 'Propietario'
        case 3: return result = 'Agente Inmobiliario'
        case 4: return result = 'Lista para vender'
        case 5: return result = 'Propiedad cancelada'
        case 6: return result = 'Para después'
        case 7: return result = 'Respondió un tercero'
      }
    return result;
  }

  static getUserByToken(token) {
    let result = {}
    USERS_ACTIVES.forEach(el => {
      if (el.token === token) result = el
    })
    return result;
  }

  static assignedRoles(roleCode) {
    let result = {}
    if (roleCode) {
      switch (roleCode) {
        case 1: return result = ROLES.admin
        case 2: return result = ROLES.callcenter
        case 3: return result = ROLES.biker
        case 4: return result = ROLES.agent
      }
    }
  return result;
  }
  static getRoleLabelByCode(roleCode) {
    let result = {}
    if (roleCode) {
      switch (roleCode) {
        case 1: return result = "Administrador"
        case 2: return result = "Agente call center"
        case 3: return result = "Rutero"
        case 4: return result = "Agente Inmobiliario"
      }
    }
  return result;
  }
  static validateViewInitialByRole(roleCode) {
    let result = ""
    if (roleCode) {
      switch (roleCode) {
        case 1: return result = "1"
        case 2: return result = "2"
        case 3: return result = "1"
        case 4: return result = "1"
      }
      return result;
    }
  }
}

export default Utils;
