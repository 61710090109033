import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Timeline, Select, Alert, InputNumber, notification } from 'antd';
import Utils from '../../utils/utils';
import { ClockCircleOutlined, DownOutlined } from '@ant-design/icons';
import { PROPERTY_STATES_FILTER, USERS_ACTIVES } from '../../constants';
import AgenciaOnlineServices from '../../services/services';
import TextArea from 'antd/lib/input/TextArea';
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(spanishStrings)
const moment = require('moment-timezone');

const Meta = { Card }
const CloseView = ({ allPropertiesBD, allPropertiesRef }) => {
  const [propertiesFiltereds, setPropertyFiltereds] = useState([])
  const [allPropertiesLoaded, setAllPropertiesLoaded] = useState([])
  const [showModalClose, setShowModalClose] = useState(false)
  const [showModalBook, setShowModalBook] = useState(false)
  const [propertySelected, setPropertySelected] = useState({})
  const [agentSelected, setAgentSelected] = useState({})
  const [infoToUpdate, setInfoToUpdate] = useState({finalPrice: null, desc: '', finalState: null})
  console.log(allPropertiesBD)
  const getAllPropertiesFirebase = () => {
    let allIds = []
    let propertiesAux = []
    allPropertiesRef.on("value", (snapshot) => {
         if (snapshot.val() !== null) {
           propertiesAux = Object.values(snapshot.val()) && Object.values(snapshot.val());
           propertiesAux = Object.values(propertiesAux)
          setAllPropertiesLoaded(propertiesAux)
           console.log(propertiesAux, "salesAux")
         }
         return;
       }, (error) => {
         console.log("ERROR: " + error.code);
       });
    }

  const handleOption = (agent) => {
    // getAllPropertiesFirebase()
    let result = []
    console.log(agent)
    console.log(allPropertiesBD)
    if (agent && allPropertiesBD.length) {
      allPropertiesBD.forEach(e => {
        if (e.assignedTo === agent.idUser) result.push(e)
      });
    }
    console.log(result)
    setPropertyFiltereds(result)
    setAgentSelected(agent)
  }
  const openModal = (obj) => {
    console.log(obj)
    setPropertySelected(obj)
    if (obj && obj.infoByClose && obj.infoByClose.comment && obj.infoByClose.finalPrice) setInfoToUpdate({comment: obj.infoByClose.comment, finalPrice: obj.infoByClose.finalPrice})

    setShowModalClose(true)
  }
  const openModalBook = () => {
    setShowModalBook(true)
  }
  const closeModalBook = () => {
    setShowModalBook(false)
  }
  const closeModal = () => {
    setShowModalClose(false)
  }
  const getIsNegotiableLabel = (type) => {
    let result = ""
    if (type) {
      if (type === 1) result = "Si es negociable"
      if (type === 2) result = "No negociable"
    }
    return result;
  }
  const getAntiquyTypeName = (type) => {
    let result = ""
    if (type) {
      if (type === 1) result = "A estrenar"
      if (type === 2) result = "Años de antigüedad"
      if (type === 3) result = "En construcción"
    }
    return result;
  }
  const updateAndClose = () => {
    let aux = { ...propertySelected }
    const service = new AgenciaOnlineServices(allPropertiesRef)
    if (infoToUpdate) {
      if (!infoToUpdate.finalState) alert("Debe seleccionar un estado final diferente de agente o propietario")
      else {
        console.log(aux)
        console.log(infoToUpdate)
        if (aux && aux.infoByClose) {
        console.log('actualizar', propertySelected)
        console.log('actualizar', infoToUpdate)
        aux.stateProperty = infoToUpdate.finalState
        // aux.infoByClose = {
        //   comment: '',
        //   finalPrice: 0
        // }
        console.log(infoToUpdate)
        if (!aux.infoByClose.comment) aux.infoByClose.comment = infoToUpdate.desc
        if (!aux.infoByClose.finalPrice) aux.infoByClose.finalPrice = infoToUpdate.finalPrice
        console.log(aux)
        service.updatePropertyDB(aux.keyBD, aux)
        setShowModalClose(false)
        setInfoToUpdate({finalPrice: null, desc: '', finalState: null})
        setPropertySelected({})
        openNotification()
        }
      }
    }
  }
    const openNotification = () => {
    notification.success({
      message: `Actualización de inmueble`,
      description:
        'La propiedad fue actualizada con éxito',
        duration: 1.2
    });
  };
  const handleInfoToUpdate = (option, id) => {
    let aux = { ...propertySelected }
    console.log(aux)
    aux.infoByClose = {
      comment: (aux.infoByClose && aux.infoByClose.comment) ? aux.infoByClose.comment : '',
      finalPrice: (aux.infoByClose && aux.infoByClose.finalPrice) ? aux.infoByClose.finalPrice : 0,
    }
    let auxInfoToUpdate = { ...infoToUpdate }
    console.log(option)
    console.log(id)
    if (id === "desc") {
       if (aux.infoByClose) {
      aux.infoByClose.comment = option.target.value
        console.log(aux)
      return setPropertySelected(aux)
       }
    } else if (id === "finalState") {
      if (auxInfoToUpdate) {
        auxInfoToUpdate.finalState = option
    // setPropertySelected(aux)
      return setInfoToUpdate(auxInfoToUpdate)
      }
    } else {
      if (aux.infoByClose) {
        aux.infoByClose.finalPrice = option
    // setInfoToUpdate(aux)
    setPropertySelected(aux)
      }
    }
  }
  console.log(propertySelected)
  console.log(propertiesFiltereds, "propertiesFiltereds")
  console.log(propertiesFiltereds === [])
  console.log(agentSelected)
  return (
    <React.Fragment>
      <h1>SELECCIONE UN AGENTE</h1>
          <br />
      {agentSelected !== {} && <h5 className="text-info-mobile">Haga scroll hacia abajo</h5>}
      {agentSelected !== {} && <DownOutlined  id="info-mobile"/>}
      
      <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
        {
          USERS_ACTIVES && USERS_ACTIVES.map(e => {
            if (e.role === 4) 
              return (              
                <Col className="gutter-row">
                <div className="1 card-real-estate call-center-close " onClick={() => handleOption(e)}>
                  <div className="card_image"> <img src="./images/filter.png" className="img-responsive"/> </div>
                  <div className="card_title title-white">
                    <p className="p-property">{e.fullNames} </p>
                  </div>
                </div>
              </Col>
              )
          })
        }
      </Row>
      <br />
      <br/>
      {(agentSelected !== {} || propertiesFiltereds.length > 0) && <hr style={{border: "1px solid black"}} />}
           { (agentSelected && agentSelected.fullNames && propertiesFiltereds.length) ? <h3>Estas viendo los inmuebles asignados a {agentSelected.fullNames}</h3> :
           null
           }

           {
             (agentSelected && agentSelected.fullNames && propertiesFiltereds.length === 0) && <h3>{agentSelected.fullNames}, no cuentas con propiedades asignadas</h3>
           }

      <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }} id="row-all-stock">
      {

          (propertiesFiltereds && propertiesFiltereds.length > 0) && propertiesFiltereds.map(el => {
            return (
              <Col>
              <Card
                  hoverable
                  id="card-product-stock"
                  className="card-all-stock"
                  style={{ width: 180 }}
                  >
                    <b style={{color: "red"}}> 
                      <TimeAgo date={el.dateRegistered} formatter={formatter} />
                    </b>
                  <br></br><br></br>
                  <b>{(el.distric) && el.distric}</b>
                  <h5>{(el.phone) && el.phone}</h5>
                  <h5>{(el.propertyType) && Utils.changeNameType(el.propertyType)}</h5>
                  <h5>{(el.stateProperty) && Utils.changeStatebyType(el.stateProperty)}</h5>
                  <Button type="primary" size="large" onClick={() => openModal(el)}>VER MAS</Button>
                  <br></br><br></br>
                  <Button type="primary" size="large" onClick={() => openModalBook(el)}>VER AGENDA</Button>
              </Card>
                  <br></br>
              </Col>
            )
          })
          // :
          // (agentSelected === {} && propertiesFiltereds.length === 0) ? <h1>SELECCIONE UN AGENTE INMOBILIARIO</h1> : null
        }
        </Row>
          <Modal visible={showModalClose} onOk={() => null} onCancel={() => closeModal()} width={900} cancelText="CERRAR" okText="ACTUALIZAR" style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} className="modal-close"
            >
              <Row>
                <table id="table-all-filters">
            <thead>
              <tr>
                <th scope="col">Tipo de propiedad</th>
                <th scope="col">Distrito</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Estado actual</th>
              </tr>
            </thead>
            <tbody>
              {
                propertySelected && 
                    <tr>
                      <td data-label="Tipo de propiedad">{propertySelected.propertyType && Utils.getNameTypePropertyByCode(propertySelected.propertyType)}</td>
                      <td data-label="Distrito">{propertySelected.distric}</td>
                      <td data-label="Telefono">{propertySelected.phone}</td>
                      <td data-label="Estado actual">{propertySelected.stateProperty && Utils.getNameStateByCode(propertySelected.stateProperty)}</td>
                    </tr>
              }
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <br />
                <Col span={12}>
                  {
                  (propertySelected && propertySelected.information && propertySelected.information.address && 
                    propertySelected.information.features && propertySelected.information.price) &&
                        <Timeline mode="left">
                          <Timeline.Item color="green"><b>Ubicación: </b>{propertySelected.information.address.address}</Timeline.Item>
                          <Timeline.Item color="green"><b>Teléfono de referencia: </b>{propertySelected.information.address.phone}</Timeline.Item>
                          <Timeline.Item color="green"><b>Habitaciones: </b>{propertySelected.information.features.bedrooms}</Timeline.Item>
                          <Timeline.Item color="green"><b>Baños completos: </b>{propertySelected.information.features.completeBathrooms} </Timeline.Item>
                          <Timeline.Item color="green"><b>Baños medios: </b>{propertySelected.information.features.middleBathrooms} </Timeline.Item>
                          <Timeline.Item color="green"><b>Estacionamientos: </b>{propertySelected.information.features.parkings} </Timeline.Item>
                          {(propertySelected.information.features.antiquy.type) && <Timeline.Item color="green"><b>Antigüedad: </b>{getAntiquyTypeName(propertySelected.information.features.antiquy.type)}
                          {(propertySelected.information.features.antiquy.type == 2) && ` de ${propertySelected.information.features.antiquy.years}`}</Timeline.Item>}
                          <Timeline.Item color="green"><b>Área total: </b>{propertySelected.information.features.totalArea} </Timeline.Item>
                          <Timeline.Item color="green"><b>Área construida: </b>{propertySelected.information.features.totalBuilded} </Timeline.Item>
                          <Timeline.Item color="green"><b>Descripción: </b>{propertySelected.information.features.desc} </Timeline.Item>
                          <Timeline.Item color="green"><b>Precio: </b>{(propertySelected.information.price.currencyType === 1 ? "S/" : "$")} {propertySelected.information.price.price} </Timeline.Item>
                          <Timeline.Item color="green"><b>Es negociable: </b>{getIsNegotiableLabel(propertySelected.information.price.isNegotiable)}</Timeline.Item>
                        </Timeline>
                  }
                </Col>
                <Col span={12}>
                  <Timeline mode="left">
                        <Timeline.Item color="red"><b>Precio final:</b>
                          <InputNumber min={1} disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5)} value={(propertySelected.infoByClose) && propertySelected.infoByClose.finalPrice} id="finalPrice" onChange={(evt) => handleInfoToUpdate(evt, "finalPrice")} />
                        </Timeline.Item>
                        <Timeline.Item color="red"><b>Anotación final:</b>
                        <TextArea rows={4} style={{ width: 250 }} disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5)} id="desc" value={(propertySelected.infoByClose && propertySelected.infoByClose.comment) && propertySelected.infoByClose.comment} onChange={(evt) => handleInfoToUpdate(evt, "desc")}/>
                        </Timeline.Item>
                        <Timeline.Item color="red"><b>Estado final:</b>
                          <Select placeholder="Selecciona" disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5)} style={{ width: 185 }} id="finalState" value={(infoToUpdate.finalState) ? Utils.changeStatebyType(infoToUpdate.finalState) : (propertySelected.stateProperty) && Utils.changeStatebyType(propertySelected.stateProperty)} onChange={(evt) => handleInfoToUpdate(evt, "finalState")}>
                                {
                                PROPERTY_STATES_FILTER && PROPERTY_STATES_FILTER.map(e => { 
                                if (e.value > 3 && e.value < 6) return <Select.Option value={e.value}>{e.label}</Select.Option>
                                })
                              }
                            </Select>
                        </Timeline.Item>
                  </Timeline>
                  <Alert
                  message="Al actualizar y cerrar, la propiedad no podrá volver a ser actualizada. Comuniquese con el administrador"
                  type="warning"
                  showIcon
                />
                <br />
                <Button type="primary" shape="round" size="large" onClick={() => updateAndClose()}
                  disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5) && true}
                >
                  ACTUALIZAR Y CERRAR
                </Button>
                </Col>
              </Row>
          </Modal>
          <Modal visible={showModalBook} onOk={() => null} onCancel={() => closeModalBook()} width={400} cancelText="CERRAR" okText="ACTUALIZAR" style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} className="modal-close"
            >
              AGENDA
            </Modal>
      </React.Fragment>
  )
}

export default CloseView;
