import React, { useState } from 'react';

const FooterComponent = () => {  
    return (
 
    <div class="home-footer home-init">
    <div class="home-wrapper">
        <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                <div class="osLight footer-header">Compañía</div>
                <ul class="footer-nav pb20">
                    <li><a href="#">About</a></li>
                    <li><a href="#">Jobs</a></li>
                    {/* <li><a href="#">Press</a></li>
                    <li><a href="blog.html">Blog</a></li>
                    <li><a href="#">Help</a></li>
                    <li><a href="#">Policies</a></li>
                    <li><a href="#">Terms & Privacy</a></li> */}
                </ul>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
                <div class="osLight footer-header">Descúbrenos</div>
                <ul class="footer-nav pb20">
                    <li><a href="#">Become a Member</a></li>
                    <li><a href="#">Properties List</a></li>
                    {/* <li><a href="#">Sign In</a></li>
                    <li><a href="#">Widgets</a></li>
                    <li><a href="#">Components</a></li>
                    <li><a href="#">Tables</a></li>
                    <li><a href="#">Lists</a></li> */}
                </ul>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div class="osLight footer-header">Contactos</div>
                <ul class="footer-nav pb20">
                    <li class="footer-phone"><span class="fa fa-phone"></span> 994 381 708</li>
                    <li class="footer-address osLight">
                        <p>Jr Vulcano Mz B Lte 7</p>
                        <p>Urb. La libertad, Santiago de Surco</p>
                        <p>Perú - Lima</p>
                    </li>
                    <li><a href="#" class="btn btn-sm btn-icon btn-round btn-o btn-white">
                      <span class="fa fa-facebook"></span></a> 
                      <a href="#" class="btn btn-sm btn-icon btn-round btn-o btn-white"><span class="fa fa-twitter"></span></a> 
                      <a href="#" class="btn btn-sm btn-icon btn-round btn-o btn-white"><span class="fa fa-google-plus"></span></a> 
                      <a href="#" class="btn btn-sm btn-icon btn-round btn-o btn-white"><span class="fa fa-linkedin"></span></a> </li>
                </ul>
            </div>
            {/* <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <div class="osLight footer-header">Subscribe to Our Newsletter</div>
                <form role="form">
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="Email Address" />
                    </div>
                    <div class="form-group">
                        <a href="#" class="btn btn-green btn-block">Subscribe</a>
                    </div>
                </form>
            </div> */}
        </div>
        <div class="copyright">
            <p class="copyright-text">Copyright &copy; 2020 - 2021 Todos los derechos reservados por 
            <a href="https://starscorporation.pe/" target="_blank"> Stars Corporation</a>
                </p></div>
    </div>
</div>
  )
}

export default FooterComponent;
