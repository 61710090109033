import React, { useState } from 'react';
import { Alert, Button, Modal, Form, Input, notification } from 'antd';
import { USER_ADMIN, USER_AUTH } from './../../constants'

const HomeComponent = ({ reference, propsAux }) => {
    const [modalLoginActive, setModalLoginActive] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const layoutLogin = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      const tailLayoutLogin = {
        wrapperCol: { offset: 8, span: 16 },
      };
      const closeModalLogin = () => {
        setModalLoginActive(false)
     }
     const openModalLogin = () => {
        setModalLoginActive(true)
     }
     const openNotification = () => {
        notification.error({
            className: 'login-notification',
          message: `LOGIN`,
          description:
            'Usuario y contraseña inválido',
            duration: 1.2
        });
      };
    const onFinishLogin = (values) => {
        console.log('Success:', values);
        let resultError = []
        if (values) {
            USER_AUTH.some(e => {
                if (values.username === e.id && values.password === e.password && propsAux && propsAux.history) {
                    // propsAux.history.push('/dashboard')
                    console.log(e, 'usuario iniciando sesion')
                    localStorage.setItem("token", e.token)
                    // resultError = [];
                    propsAux.history.push("/dashboard")
                    console.log()
                    return notification.destroy()
                }
                else {
                    console.log("usuario invalido")
                    return setShowAlert(true)
                    // USER_AUTH.every(e => {
                    //     if (values.username !== e.id && values.password !== e.password) {
                    //         console.log("usuario invalido")
                    //         openNotification()
                    //         return false
                    //     }
                    // });
                }
            });
            USER_AUTH.every(e => {
                if (values.username !== e.id && values.password !== e.password) {
                    console.log("usuario invalido")
                    openNotification()
                    return false
                }
            });
        }
      };
      const cancelLogin = () => {
          setModalLoginActive(false)
      }
      const onFinishFailedLogin = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
  return (
<div id="hero-container">
            {/* <ul class="cb-slideshow">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul> */}
            
            <div class="home-header">
                <div class="home-logo osLight"><span class="fa fa-home"></span>AGENCIA XXXXX</div>
                <a href="#" class="home-navHandler visible-xs"><span class="fa fa-bars"></span></a>
                <div class="home-nav">
                    <ul>
                        {/* <li><a href="#" data-toggle="modal" data-target="#signup">Sign Up</a></li> */}
                        <li><a onClick={() => openModalLogin()} >LOGIN</a></li>
                        <li><a href="/real-estate" class="btn btn-green">VER PROPIEDADES</a></li>
                    </ul>
                </div>
            </div>
            {
                !modalLoginActive &&
                    <div class="home-caption">
                        <div class="home-title">TITULO AGENCIA INMOBILIARIA ONLINE</div>
                        {/* <div class="home-subtitle">With Reales - Real Estate HTML Template</div> */}
                        {/* <a href="#" class="btn btn-lg btn-black">Learn More</a> */}
                    </div>
            }
            {
                modalLoginActive &&
                <div id="modal-login">
                <Form
                    {...layoutLogin}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailedLogin}
                    >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input style={{width: 200}} />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password style={{width: 200}} />
                    </Form.Item>
                    
                    {showAlert && <Alert message="Usuario inválido" type="error" showIcon />}
                    <br />
                    <Form.Item {...tailLayoutLogin}>
                        <Button type="primary" htmlType="ENTRAR" style={{background: '#1FE42D', border: '1px solid #1FE42D'}} >
                        INGRESAR
                        </Button>
                    </Form.Item>
                    <Form.Item {...tailLayoutLogin}>
                        <Button onClick={() => cancelLogin()} style={{background: '#1FE42D', border: '1px solid #1FE42D', color: "white"}} >
                        CANCELAR
                        </Button>
                    </Form.Item>
                    </Form>
                </div>
            }
            <div class="search-panel">
                
            
             {
                !modalLoginActive &&
                <form class="form-inline" role="form">
                    <div class="form-group">
                        <input type="text" class="form-control" id="city" placeholder="Distrito" autocomplete="off" />
                    </div>
                    <div class="form-group hidden-xs adv">
                        <a href="#" data-toggle="dropdown" class="btn btn-white dropdown-toggle">
                            <span class="dropdown-label">Habitaciones</span> <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu dropdown-select">
                            <li class="active"><input type="radio" name="bedno" checked="checked" /><a href="#">Habitaciones</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">1</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">2</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">3</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">4</a></li>
                        </ul>
                    </div>
                    {/* <div class="form-group hidden-xs adv">
                        <a href="#" data-toggle="dropdown" class="btn btn-white dropdown-toggle">
                            <span class="dropdown-label">Baños</span> <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu dropdown-select">
                            <li class="active"><input type="radio" name="bathno" checked="checked" /><a href="#">Baños</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">1</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">2</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">3</a></li>
                            <li><input type="radio" name="bathno" /><a href="#">4</a></li>
                        </ul>
                    </div> */}
                    <div class="form-group hidden-xs adv">
                        <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input class="form-control price" type="text" placeholder="Desde" />
                        </div>
                    </div>
                    <div class="form-group hidden-xs adv">
                        <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input class="form-control price" type="text" placeholder="Hasta" />
                        </div>
                    </div>
                    <div class="form-group hidden-xs adv">
                        <div class="checkbox custom-checkbox"><label><input type="checkbox" /><span class="fa fa-check" ></span> Alquiler</label></div>
                    </div>
                    <div class="form-group hidden-xs adv">
                        <div class="checkbox custom-checkbox"><label><input type="checkbox" /><span class="fa fa-check" ></span> A la venta</label></div>
                    </div>
                    <div class="form-group">
                        <a href="explore.html" class="btn btn-green">BUSCAR</a>
                        <a href="#" class="btn btn-o btn-white pull-right visible-xs" id="advanced">Advanced Search <span class="fa fa-angle-up"></span></a>
                    </div>
                </form>
            }
            </div>
            {/* <Modal visible={modalLoginActive} onCancel={() => closeModalLogin()} width={450} cancelText="CERRAR" style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: true}}
            >
                <div id="modal-login">
                <Form
                    {...layoutLogin}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailedLogin}
                    >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input style={{width: 200}} />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password style={{width: 200}} />
                    </Form.Item>
                    
                    {showAlert && <Alert message="Usuario inválido" type="error" showIcon />}
                    <br />
                    <Form.Item {...tailLayoutLogin}>
                        <Button type="primary" htmlType="ENTRAR" style={{background: '#1FE42D', border: '1px solid #1FE42D'}} >
                        INGRESAR
                        </Button>
                    </Form.Item>
                    </Form>
                </div>
            </Modal>
         */}
        </div>
  )
}

export default HomeComponent;
