class AgenciaOnlineServices {
  constructor(ref) {
    this.ref = ref;
  }
	deleteClientDB = (client) => {
		const clientsRef = this.ref.child("properties");
		return clientsRef.child(client.id).remove();
  }
  
	updateClientDB = (id, client) => {
		const clientsRef = this.ref.child("clients");

		return clientsRef.child(id).child('property').update(client);
  }
  //APIS PROPERTIES

  saveProperty = (obj) => {
    if (this.ref) {
      const clientsRef = this.ref.child("properties");
      return clientsRef.push(obj).then((res) => {
        return {
              message: "registro exitoso",
              response: res
        }
      }, error => {
            return {
              message: "registro fallído",
              error: error
             }
      })
    }
	}

  updatePropertyDB = (id, property) => {
    const aux = id && id.replace('IDPROP', '')
		const clientsRef = this.ref.child("properties");

		return clientsRef.child(aux).update(property).then((res) => {
      return {
        message: "Actualización exitoso",
        response: res
  }
    }, error => {
      return {
        message: "Actualización fallída",
        error: error
       }
    });
  }
}

export default AgenciaOnlineServices;
