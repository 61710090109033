import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MenuComponent from '../shared/menu';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const PropertiesRealEstate = ({ reference }) => {
  const [showFilters, setShowFilters] = useState(false)
  const defaultProps = {
    center: {
      lat: -12.167934,
      lng: -76.993663
    },
    zoom: 17
  };
  const renderMarkers = (map, maps) => {
    const locations = [
      { lat: -31.56391, lng: 147.154312,title: 'Hello World!' },
      { lat: -33.718234, lng: 150.363181, title: 'Hello World! 2' },
      { lat: -33.727111, lng: 150.371124, title: 'Hello World! 3' },
    ]
    locations.map(el => {
      return new maps.Marker({
        position:  {
          lat: el.lat,
          lng: el.lng
        },
        map,
        title: el.title
      });
      // <AnyReactComponent
      //   lat={el.lat}
      //   lng={el.lng}
      //   text={el.title}
      // />
      
    })
  }
  const showFiltersUI = () => {
      if (showFilters) return setShowFilters(false)
      setShowFilters(true)
  }
  const closeFiltersUI = () => {
      setShowFilters(false)
  }
  return (
    <div>
        <MenuComponent />
          <div id="leftSide">
            <nav class="leftNav scrollable">
                <div class="search">
                    <span class="searchIcon icon-magnifier"></span>
                    <input type="text" placeholder="Search for houses, apartments..." />
                    <div class="clearfix"></div>
                </div>
                <ul>
                    {/* <li><a href="explore.html"><span class="navIcon icon-compass"></span><span class="navLabel">Inicio</span></a></li> */}
                    <li><a href="/"><span class="navIcon icon-home"></span><span class="navLabel">Inicio</span></a></li>
                </ul>
            </nav>
        </div>
        <div id="wrapper">
    <div id="mapView" style={{ height: '90vh', width: '50%' }}>
        <GoogleMapReact
          bootstrapURLKeys="AIzaSyC0x_AJli7M3f_ctI1tvvhLz6vcG5IDxh0"
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps}) => renderMarkers(map, maps)}
        >
          {/* <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="DEPA 1"
          />
          <AnyReactComponent
            lat={60.955413}
            lng={33.337844}
            text="DEPA 2"
          /> */}
        </GoogleMapReact>
        {/* <div class="mapPlaceholder"><span class="fa fa-spin fa-spinner"></span> Loading map...</div> */}
    </div>
    <div id="content">
        <div class="filter">
            <h1 class="osLight">Personaliza tu búsqueda</h1>
            <a href="#" class="handleFilter">
                <span class="icon-equalizer"></span>
            </a>
            <div class="clearfix"></div>
            <form class="filterForm" style={{display: showFilters ? 'block' : 'none', transition: 'all 3s ease-out'}}>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                        <div class="formField">
                            <label>Tipo de propiedad</label>
                            <a href="#" data-toggle="dropdown" class="btn btn-gray dropdown-btn dropdown-toggle propTypeSelect">
                                <span class="dropdown-label">Todos</span>
                                <span class="fa fa-angle-down dsArrow"></span>
                            </a>
                            <ul class="dropdown-menu dropdown-select full" role="menu">
                                <li class="active"><input type="radio" name="pType" checked="checked" /><a href="#">Todos</a></li>
                                <li><input type="radio" name="pType" /><a href="#">Alquiler</a></li>
                                <li><input type="radio" name="pType" /><a href="#">A la venta</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                        <div class="formField">
                            <label>Rango de precio</label>
                            <div class="slider priceSlider">
                                <div class="sliderTooltip">
                                    <div class="stArrow"></div>
                                    <div class="stLabel"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 formItem">
                        <div class="formField">
                            <label>Rango de área</label>
                            <div class="slider areaSlider">
                                <div class="sliderTooltip">
                                    <div class="stArrow"></div>
                                    <div class="stLabel"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 formItem">
                        <div class="formField">
                            <label>Habitaciones</label>
                            <div class="volume">
                                <a href="#" class="btn btn-gray btn-round-left"><span class="fa fa-angle-left"></span></a>
                                <input type="text" class="form-control" readonly="readonly" value="1" />
                                <a href="#" class="btn btn-gray btn-round-right"><span class="fa fa-angle-right"></span></a>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 formItem">
                        <div class="formField">
                            <label>Baños</label>
                            <div class="volume">
                                <a href="#" class="btn btn-gray btn-round-left"><span class="fa fa-angle-left"></span></a>
                                <input type="text" class="form-control" readonly="readonly" value="1" />
                                <a href="#" class="btn btn-gray btn-round-right"><span class="fa fa-angle-right"></span></a>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="resultsList">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <a href="single.html" class="card">
                        <div class="figure">
                            <img src="images/prop/1-1.png" alt="image" />
                            <div class="figCaption">
                                <div>$1,550,000</div>
                                <span class="icon-eye"> 200</span>
                                <span class="icon-heart"> 54</span>
                                <span class="icon-bubble"> 13</span>
                            </div>
                            <div class="figView"><span class="icon-eye"></span></div>
                            <div class="figType">FOR SALE</div>
                        </div>
                        <h2>PROPIEDAD 1</h2>
                        <div class="cardAddress"><span class="icon-pointer"></span> 39 Remsen St, Brooklyn, NY 11201, USA</div>
                        <div class="cardRating">
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star-o"></span>
                            (146)
                        </div>
                        <ul class="cardFeat">
                            <li><span class="fa fa-moon-o"></span> 3</li>
                            <li><span class="icon-drop"></span> 2</li>
                            <li><span class="icon-frame"></span> 3430 Sq Ft</li>
                        </ul>
                        <div class="clearfix"></div>
                    </a>
                </div>
               
            </div>
            <ul class="pagination">
                <li class="disabled"><a href="#"><span class="fa fa-angle-left"></span></a></li>
                <li class="active"><a href="#">1</a></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#"><span class="fa fa-angle-right"></span></a></li>
            </ul>
        </div>
    </div>
        <div class="clearfix"></div>
        </div>  
      </div>
    )
  }

  export default PropertiesRealEstate;
