import React, { useState, useEffect } from 'react';
import { Form, Input, Button, InputNumber, Select , Row, Col, Alert , Modal, notification } from 'antd';
import { DISTRICTS, PROPERTY_TYPE } from '../../constants';
import AgenciaOnlineServices from '../../services/services';
import Utils from '../../utils/utils';
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(spanishStrings)

const moment = require('moment-timezone');
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const { Option } = Select;
const RealEstatesView = ({ reference, onFinishFailed = () =>{}, layout, propsAux, allPropertiesBD }) => {
  const [selectOption, setSelectOption] = useState("0")
  const token = localStorage.getItem("token") || ''
  const [userActive, setUserActive] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [propertiesFilteredsByDateRegistered, setPropertiesFilteredsByDateRegistered] = useState([])
  const [isSaved, setIsSaved] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  let user = {}
  useEffect(() => {
    setTimeout(() => {
      if (!token) propsAux.history.push("/")
      const aux = document.querySelectorAll(".ant-notification-notice")
      if (aux && aux[0] && allPropertiesBD === []) {
          console.log(aux[0])
          aux[0].remove()
      }
      if (token) {
        user = Utils.getUserByToken(token)
      }
      if (user) setUserActive(user)
    }, 100)
    if (isSaved) {
    //   getData()
    filterByDate()
    setShowModal(true)
    setIsSaved(false)
    }
  })
  console.log(userActive)
  // const getData = () => {
  //   const response = getAllPropertiesFirebase();
  //   console.log(response)
  //   setIsSaved(false)

  // }
  const handleOption = (option) => {
    setSelectOption(option)
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  const openNotification = () => {
    notification.success({
      message: `Se registro con éxito`,
      description:
        'La propiedad ha sido guardada.',
        duration: 1.2
    });
  };
  const openNotificationError = () => {
    notification.error({
      message: `Error de registro`,
      description:
        'La propiedad no ha sido guardada.',
        duration: 1.2
    });
  };
  const closeModal = () => {
      setShowModal(false)
  }
  const openModal = () => {
      setShowModal(true)
  }
  const onFinish = (values) => {
    setIsSaving(true)
    const service = new AgenciaOnlineServices(reference);
    let exist = false
    if(values) {
      values.id = Math.floor(Math.random() * (1000000 - 10000)) + 100
      // funcion validadora que sea unico en BD el id generado
      values.stateProperty = 1
      values.assignedTo = ""
      values.stateByCall = 0
      values.dateRegistered = ""
      allPropertiesBD.forEach(e => {
        if (e.phone === values.phone) {
           console.log("numero ya existe")
           exist = true
        }
      });
      if (!exist) {        
      console.log("numero nuevo")
      }
      const isOnline = window. navigator. onLine
      if (isOnline) {
        const momentNow = moment.tz(new Date(), "America/Lima").format()
        values.dateRegistered = momentNow
        service.saveProperty(values).then(res => {
          console.log(res)
          setIsSaving(false)
          setIsSaved(true)
          // filterByDate()
          openNotification()
        },
          error => {
            setIsSaving(false)
            openNotificationError()
            console.log(error)
          })
        // 
      } else {
      }
    }
};
  console.log(allPropertiesBD)
  const filterByDate = () => {
    let result = []
    if (allPropertiesBD && allPropertiesBD.length) {
      let aux = [ ...allPropertiesBD ];

      aux.forEach(e => {
        if (e.dateRegistered) result.push(e)
      })
      
  console.log(result)
    }
    result = result.reverse()
    let auxResult = false
    let newListToday = []
    if (result && result.length > 0) {
      result.forEach(k => {
        auxResult = filterIsBetweenToday(k.dateRegistered)
        if (auxResult) newListToday.push(k)
      })
      if (newListToday && newListToday.length > 0) setPropertiesFilteredsByDateRegistered(newListToday)
    }
  }
  console.log(propertiesFilteredsByDateRegistered)
  const changeNameType = (type) => {
    let result = ""
      switch (type) {
        case 1: return result = 'Terreno'
        case 2: return result = 'Casa'
        case 3: return result = 'Departamento'
        case 4: return result = 'Local'
      }
    return result;
  }
  const filterIsBetweenToday = (date) => {
    let result = false;
    const todayDate = moment.tz(new Date(), "America/Lima").format()
    // console.log(todayDate)
    let initDay = todayDate.split("T")
    let finalDay = todayDate.split("T")
    initDay = initDay[0]+"T00:00:00-05:00"
    finalDay = finalDay[0]+"T23:59:59-05:00"
    initDay = Date.parse(initDay)
    finalDay = Date.parse(finalDay)
    date = Date.parse(date)
    console.log(initDay)
    console.log(finalDay)
    console.log(date)
    // if (moment.tz(Date.parse(date).isAfter(Date.parse(initDay)))) result = true
    if (initDay <= date && date <= finalDay) result = true
    return result
  }
  propertiesFilteredsByDateRegistered.forEach(k => {
    let result = filterIsBetweenToday(k.dateRegistered)
    if (result) console.log(result)
  })
  return (
 <React.Fragment>
     <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
       <Col className="gutter-row">
        <div className="1 card-real-estate card-real-estate-register" onClick={() => handleOption("1")}>
          <div className="card_image"> <img src="./images/inmobiliaria/btn-add.png" className="img-responsive"/> </div>
          <div className="card_title title-white">
            <p>AGREGAR </p>
          </div>
        </div>
          </Col>
    </Row>
    {
      selectOption === "1" &&
      <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      id="form-register-property"
    >
      <Form.Item
        label="Tipo de inmueble"
        name="propertyType"
        rules={[{ required: true, message: 'Tipo de inmueble es requerido' }]}
      >
      <Select placeholder="Selecciona" style={{ width: 220 }} onChange={handleChange}>
          {
            PROPERTY_TYPE && PROPERTY_TYPE.map(e => <Select.Option value={e.value}>{e.label}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        label="Distrito"
        name="distric"
        rules={[{ required: true, message: 'Distrito es requerido' }]}
      >
      <Select placeholder="Selecciona" style={{ width: 220 }} onChange={handleChange}>
          {
            DISTRICTS && DISTRICTS.map(e => <Select.Option value={e}>{e}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        label="Teléfono de contacto"
        name="phone"
        rules={[{ required: true, message: 'Teléfono es requerido' }]}
      >
        <Input style={{ width: 110 }}/>
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" id="btn-register" disabled={isSaving}>
          {
            isSaving ? "REGISTRANDO" : "REGISTRAR"
          }
        </Button>
      </Form.Item>
    </Form>
    }
    <Modal className="modal-registereds-today" visible={showModal} onCancel={() => closeModal()} width={650} style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} cancelText="CERRAR"
            >
              <h5>
              (*) LLEVAS UN TOTAL DE {propertiesFilteredsByDateRegistered && propertiesFilteredsByDateRegistered.length} INMUEBLES REGISTRADOS HOY
              </h5>
              <br />
              <div class="table-responsive">
              <table className="styled-table table">
                <thead>
                  <tr>
                    <td>
                      Tipo
                    </td>
                    <td>
                      Distrito
                    </td>
                    <td>
                      Teléfono
                    </td>
                    <td>
                      Hora de registro
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    (propertiesFilteredsByDateRegistered && propertiesFilteredsByDateRegistered.length) && propertiesFilteredsByDateRegistered.map(e => {
                      return (
                        <tr>
                          <td>
                            {e.propertyType && changeNameType(e.propertyType)}
                          </td>
                          <td>
                            {e.distric}
                          </td>
                          <td>
                            {e.phone}
                          </td>
                          <td>
                            
                            <TimeAgo date={e.dateRegistered} formatter={formatter} />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              </div>
              </Modal>
    </React.Fragment>   
  )
}

export default RealEstatesView;
