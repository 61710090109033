import React, { useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import HomeComponent from './components/home';
import Footer from './components/shared/footer';
import PropertiesRealEstate from './components/properties';
import Dashboard from './components/dashboard';
import firebase from 'firebase';
import config from './config';
import FooterComponent from './components/shared/footer';

import 'antd/dist/antd.css';

firebase.initializeApp(config);
const publicationRef = firebase.database();
const mainRef =	publicationRef.ref('/');
const allPropertiesRef =	publicationRef.ref('/properties');

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: ''
    }
  }
  shouldComponentUpdate() {
  //  console.log(window.location.href.path)
const token = localStorage.getItem("token")
console.log(token)
  }
  
  returnToken = (token) => {
    console.log(token)
    this.setState({token})
  }
  render() {
    return (
      <BrowserRouter>
      <div style={{height: "100%"}}>
          <Switch>
            {/* <Route path="/dashboard">
              <Dashboard reference={mainRef} allPropertiesRef={allPropertiesRef} propsAux={props} />
            </Route> */}
            <Route path="/dashboard" render={(props) => 
              <Dashboard 
              returnToken={(token) => this.returnToken(token)}
              reference={mainRef} allPropertiesRef={allPropertiesRef} propsAux={props} />} />
            <Route path="/real-estate">
              <PropertiesRealEstate reference={mainRef} />
            </Route>
            {/* <Route path="/">
              <HomeComponent reference={mainRef} />
            </Route> */}
            <Route path="/" render={(props) => <HomeComponent reference={mainRef} propsAux={props} />} />
          </Switch>
          
      </div>
            <FooterComponent />   
      </BrowserRouter>
    );
  }
}

export default App;
