import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Alert, Timeline, Input } from 'antd';
import Utils from '../../utils/utils';
import TextArea from 'antd/lib/input/TextArea';
import { USERS_ACTIVES } from '../../constants';

const ForLateView = ({ allPropertiesBD }) => {
  const [showModal, setShowModal] = useState(false)
  const [propertySelected, setPropertySelected] = useState({})
  const closeModal = () => {
    setShowModal(false)
  }
  const openModal = (el) => {
    setShowModal(true)
    setPropertySelected(el)
  }
  const getNameAgentByCode = (code) => {
    let result = ""
    USERS_ACTIVES && USERS_ACTIVES.forEach(e => {
      if (e.idUser === code) result = e.fullNames
    })
    return result;
  }
  const getAntiquyTypeName = (type) => {
    let result = ""
    if (type) {
      if (type === 1) result = "A estrenar"
      if (type === 2) result = "Años de antigüedad"
      if (type === 3) result = "En construcción"
    }
    return result;
  }
console.log(propertySelected)
  let acumLands = []
  let acumHomes = []
  let acumApartments = []
  let acumOffices = []
  let acumForUpdate = []
  let acumWithProp = []
  let acumWithOtherAgent = []
  
  if(allPropertiesBD && allPropertiesBD.length) {
    allPropertiesBD.forEach(e => {
      if (e.stateProperty === 1) acumForUpdate.push(e)
      if (e.stateProperty === 2) acumWithProp.push(e)
      if (e.stateProperty === 3) acumWithOtherAgent.push(e)

      if (e.propertyType === 1) acumLands.push(e)
      if (e.propertyType === 2) acumHomes.push(e)
      if (e.propertyType === 3) acumApartments.push(e)
      if (e.propertyType === 4) acumOffices.push(e)
      
    })
  }

  return (
    <React.Fragment>
    <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }} id="row-all-stock">
        {
          (allPropertiesBD && allPropertiesBD.length > 0) ? allPropertiesBD.map(el => {
            if (el.stateProperty === 6) {
            return (
              <Col>
              <Card
                  hoverable
                  id="card-product-stock"
                  className="card-all-stock"
                  style={{ width: 180 }}
                  >
                  <br></br>
                  <b>{(el.distric) && el.distric}</b>
                  <h5>{(el.phone) && el.phone}</h5>
                  <Button type="primary" size="large" onClick={() => openModal(el)}>VER MAS</Button>
              </Card>
                  <br></br>
              </Col>
            )
            }
          })
          :
          <h1>NO EXISTEN PROPIEDADES PARA DESPUES</h1>
        }
    </Row>
    <Modal visible={showModal} onOk={() => null} onCancel={() => closeModal()} width={900} cancelText="CERRAR" okText="ACTUALIZAR" style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} className="modal-close"
            >
              <Row>
                <table id="table-all-filters">
            <thead>
              <tr>
                <th scope="col">Tipo de propiedad</th>
                <th scope="col">Distrito</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Estado final</th>
              </tr>
            </thead>
            <tbody>
              {
                propertySelected && 
                    <tr>
                      <td data-label="Tipo de propiedad">{propertySelected.propertyType && Utils.getNameTypePropertyByCode(propertySelected.propertyType)}</td>
                      <td data-label="Distrito">{propertySelected.distric}</td>
                      <td data-label="Telefono">{propertySelected.phone}</td>
                      <td data-label="Estado actual">{propertySelected.stateProperty && Utils.getNameStateByCode(propertySelected.stateProperty)}</td>
                    </tr>
              }
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <br />
                <Col span={12}>
                  {
                  (propertySelected && propertySelected.information && propertySelected.information.address && 
                    propertySelected.information.features && propertySelected.information.price) &&
                        <Timeline mode="left">
                          <Timeline.Item color="green"><b>Ubicación: </b>{propertySelected.information.address.address}</Timeline.Item>
                          {propertySelected.information.address.phone && <Timeline.Item color="green"><b>Teléfono de referencia: </b>{propertySelected.information.address.phone}</Timeline.Item>}
                          <Timeline.Item color="green"><b>Habitaciones: </b>{propertySelected.information.features.bedrooms}</Timeline.Item>
                          <Timeline.Item color="green"><b>Baños completos: </b>{propertySelected.information.features.completeBathrooms} </Timeline.Item>
                          <Timeline.Item color="green"><b>Baños medios: </b>{propertySelected.information.features.middleBathrooms} </Timeline.Item>
                          <Timeline.Item color="green"><b>Estacionamientos: </b>{propertySelected.information.features.parkings} </Timeline.Item>
                          {(propertySelected.information.features.antiquy.type) && <Timeline.Item color="green"><b>Antigüedad: </b>{getAntiquyTypeName(propertySelected.information.features.antiquy.type)}
                          {(propertySelected.information.features.antiquy.type == 2) && ` de ${propertySelected.information.features.antiquy.years}`}</Timeline.Item>}
                          <Timeline.Item color="green"><b>Área total: </b>{propertySelected.information.features.totalArea} </Timeline.Item>
                          <Timeline.Item color="green"><b>Área construida: </b>{propertySelected.information.features.totalBuilded} </Timeline.Item>
                          <Timeline.Item color="green"><b>Descripción: </b>{propertySelected.information.features.desc} </Timeline.Item>
                          <Timeline.Item color="green"><b>Precio: </b>{(propertySelected.information.price.currencyType === 1 ? "S/" : "$")} {propertySelected.information.price.price} </Timeline.Item>
                        </Timeline>
                  }
                </Col>
                <Col span={12}>
                  <Timeline mode="left">
                        <Timeline.Item color="red"><b>Precio final:</b>
                          <Input min={1} value={(propertySelected.infoByClose) && propertySelected.infoByClose.finalPrice} id="finalPrice" disabled/>
                        </Timeline.Item>
                        <Timeline.Item color="red"><b>Anotación:</b>
                        <TextArea rows={4} style={{ width: 250 }} disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5)} id="desc" value={(propertySelected.infoByClose && propertySelected.infoByClose.comment) && propertySelected.infoByClose.comment} disabled/>
                        </Timeline.Item>
                        {propertySelected.assignedTo && 
                        <Timeline.Item color="green">Propiedad asignada a {getNameAgentByCode(propertySelected.assignedTo)}</Timeline.Item>
                        }
                        </Timeline>
                        {
                          propertySelected.assignedTo &&
                          <Alert
                          message="Este inmueble tiene como estado final, un resultado aprobado por el agente inmobiliario asignado."
                          type="success"
                          showIcon
                        />
                        }
                {/* <br />
                <Button type="primary" shape="round" size="large" onClick={() => closeModal()}
                  disabled={(propertySelected.stateProperty === 4 || propertySelected.stateProperty === 5) && true}
                >
                  ACTUALIZAR Y CERRAR
                </Button> */}
                </Col>
              </Row>
          </Modal>
    </React.Fragment>
  )
}

export default ForLateView;
