import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, Timeline, Select, Checkbox, notification } from 'antd';
import Utils from '../../utils/utils';
import { ClockCircleOutlined } from '@ant-design/icons';
import { USERS_ACTIVES } from '../../constants';
import AgenciaOnlineServices from '../../services/services';
import TimeAgo from 'react-timeago'
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(spanishStrings)

const Meta = { Card }
const CallCenterView = ({ allPropertiesBD, allPropertiesRef }) => {
  const [resultFilter, setResultFilter] = useState([])
  const [wasFiltered, setWasFiltered] = useState(false)
  const [propertySelected, setPropertySelected] = useState({})
  const [showModalHandleProperty, setShowModalHandleProperty] = useState(false)
  const [agentSelected, setAgentSelected] = useState("")
  const [callCenterOptionSelected, setcallCenterOptionSelected] = useState(1)
  const [optionForAssigned, setOptionForAssigned] = useState(0)
  const [showAgentName, setShowAgentName] = useState("")

  console.log(allPropertiesBD)
  const filterPropertiesByState = (properties, state) => {
    let result = []
    if (properties && state) {
    properties.map(e => {
      if (e.stateProperty === state) result.push(e)
    })
    return result;
  }
}
  const handleOption = (option) => {
    let result = []
    if (option) {
      setcallCenterOptionSelected(option)
      result = filterPropertiesByState(allPropertiesBD, option)
      }
      console.log(result)
      if (result && result.length) {
        result = result.reverse()
        setResultFilter(result)
      }
      else setResultFilter([])
      setWasFiltered(true)
    }
    const validateStateColor = (state) => {
      let result = ""
      if (state) {
        if (state === 2) result = "rgb(255, 156, 85) 4px -4px"
        if (state === 3) result = "rgb(31, 193, 27) 4px -4px"
        if (state === 4) result = "rgb(255, 85, 85) 4px -4px"
      }
      return result;
    }
    const handleProperty = (obj) => {
      setShowModalHandleProperty(true)
      setPropertySelected(obj)
      console.log(obj)
    }
    const closeModal = () => {
      setShowModalHandleProperty(false)
      setOptionForAssigned(0)
    }
    console.log(propertySelected)

    const getAntiquyTypeName = (type) => {
      let result = ""
      if (type) {
        if (type === 1) result = "A estrenar"
        if (type === 2) result = "Años de antigüedad"
        if (type === 3) result = "En construcción"
      }
      return result;
    }
    const getIsNegotiableLabel = (type) => {
      let result = ""
      if (type) {
        if (type === 1) result = "Si es negociable"
        if (type === 2) result = "No negociable"
      }
      return result;
    }
    const handleAgentUI = (values) => {
      setAgentSelected(values)
    }
    console.log(agentSelected)
    const assignedToAgent = () => {
      let aux = { ...propertySelected }
      console.log(agentSelected)
      console.log(propertySelected)
      const service = new AgenciaOnlineServices(allPropertiesRef)
      aux.assignedTo = agentSelected
      service.updatePropertyDB(aux.keyBD, aux)
      console.log(aux)
      setShowModalHandleProperty(false)
      setAgentSelected("")
      openNotification("Propiedad actualizada con éxito")
    }
    const saveToLate = () => {
      const service = new AgenciaOnlineServices(allPropertiesRef)
      let aux = { ...propertySelected }
      console.log(aux)
      aux.stateProperty = 6
      service.updatePropertyDB(aux.keyBD, aux)
      setShowModalHandleProperty(false)
      setAgentSelected("")
      openNotification("Propiedad actualizada con éxito")
    }
    const openNotification = (message) => {
      notification.success({
        message: `Actualización`,
        description: message,
          duration: 1
      });
    };
    const onChangeOption = (e) => {
      if (e === 2) setAgentSelected("")
      console.log(typeof e)
      console.log(e)
      setOptionForAssigned(e)
    }
    console.log(callCenterOptionSelected)
    console.log(optionForAssigned)
    const getNameAgentByCode = (code) => {
      let result = ""
      USERS_ACTIVES && USERS_ACTIVES.forEach(e => {
        if (e.idUser === code) result = e.fullNames
      })
      return result;
    }
    console.log(propertySelected)
    console.log(agentSelected)
  return (
    <React.Fragment>
      <h1>{(resultFilter.length === 0 && wasFiltered) ? "NO EXISTE PROPIEDADES PARA CON ESTE ESTADO" : "SELECCIONE"}</h1>
      
      <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }}>
       <Col className="gutter-row">
        <div className="1 card-real-estate call-center-filter " onClick={() => handleOption(2)}>
          <div className="card_image"> <img src="./images/filter.png" className="img-responsive"/> </div>
          <div className="card_title title-white">
            <p className="p-property">PROPIETARIOS </p>
          </div>
        </div>
      </Col>
       <Col className="gutter-row">
        <div className="1 card-real-estate call-center-filter" onClick={() => handleOption(3)}>
          <div className="card_image"> <img src="./images/filter.png" className="img-responsive"/> </div>
          <div className="card_title title-white">
            <p className="p-property">AGENTES INMOBILIARIOS</p>
          </div>
        </div>
      </Col>
      </Row>
      <hr id="call-center" />
      { (callCenterOptionSelected > 1 && resultFilter.length > 0) && <h3>Estas viendo inmuebles con {callCenterOptionSelected === 3 ? 'otros agentes inmobiliarios' : 'propietarios'}</h3>}
      <br />
      <Row gutter={{ xs: 16, sm: 16, md: 24, lg: 32 }} id="row-all-stock">
        {
          (resultFilter && resultFilter.length > 0) ? resultFilter.map(el => {
            return (
              <Col>
              <Card
                  hoverable
                  id="card-product-stock"
                  className="card-all-properties"
                  style={{ width: 180,
                  boxShadow: validateStateColor(el.stateProperty) }}
                  onClick={() => handleProperty(el)}
                  >
                  <br></br>
                    {
                      el.assignedTo &&
                      <b style={{color: "green"}}>Propiedad asignada a {getNameAgentByCode(el.assignedTo)}</b>
                    }
                  <br></br>
                  {
                  (el.dateRegistered) &&
                  <b style={{color: "red"}}>                 
                  <TimeAgo date={el.dateRegistered} formatter={formatter} />
                  </b>
                    }
                    <br />
                  <b>{(el.distric) && el.distric}</b>
                  <h5>{(el.phone) && el.phone}</h5>
                  <Button type="primary" size="large">VER MAS</Button>
              </Card>
                  <br></br>
              </Col>
            )
          })
          :
          null
        }
    </Row>
    <Modal className="modal-callcenter-product-selected" visible={showModalHandleProperty} onCancel={() => closeModal()} width={650} style={{ top: 20 }}
                okButtonProps={{hidden: true}} closable={true} cancelButtonProps={{hidden: false}} cancelText="CERRAR"
            >
          <table id="table-all-filters">
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Tipo de propiedad</th>
                <th scope="col">Distrito</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Estado actual</th>
              </tr>
            </thead>
            <tbody>
              {
                propertySelected && 
                    <tr>
                    <td data-label="Distrito">{propertySelected.id}</td>
                      <td data-label="Tipo de propiedad">{propertySelected.propertyType && Utils.getNameTypePropertyByCode(propertySelected.propertyType)}</td>
                      <td data-label="Distrito">{propertySelected.distric}</td>
                      <td data-label="Telefono">{propertySelected.phone}</td>
                      <td data-label="Estado actual">{propertySelected.stateProperty && Utils.getNameStateByCode(propertySelected.stateProperty)}</td>
                    </tr>
              }
            </tbody>
          </table>
          <br/>
          <br/>
          {
            (propertySelected && propertySelected.information && propertySelected.information.address && 
              propertySelected.information.features && propertySelected.information.price) &&
              <React.Fragment>
                {/* {
                  propertySelected.stateByCall > 0 && <h4>Existío un problema en llamada: </h4>
                } */}
                {
                  (propertySelected.assignedTo && propertySelected.assignedTo.length) && <h3>Propiedad asignada a {getNameAgentByCode(propertySelected.assignedTo)}</h3>
                }
              <Timeline mode="alternate">
                <Timeline.Item color="green"><b>Ubicación: </b>{propertySelected.information.address.address}</Timeline.Item>
                {propertySelected.information.address.phone && <Timeline.Item color="green"><b>Teléfono: </b>{propertySelected.information.address.phone}</Timeline.Item>}
                {/* <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                  laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </Timeline.Item> */}
                <Timeline.Item color="green"><b>Habitaciones: </b>{propertySelected.information.features.bedrooms}</Timeline.Item>
                <Timeline.Item color="green"><b>Baños completos: </b>{propertySelected.information.features.completeBathrooms} </Timeline.Item>
                <Timeline.Item color="green"><b>Baños medios: </b>{propertySelected.information.features.middleBathrooms} </Timeline.Item>
                <Timeline.Item color="green"><b>Estacionamientos: </b>{propertySelected.information.features.parkings} </Timeline.Item>
                {(propertySelected.information.features.antiquy.type) && <Timeline.Item color="green"><b>Antigüedad: </b>{getAntiquyTypeName(propertySelected.information.features.antiquy.type)}
                {(propertySelected.information.features.antiquy.type == 2) && ` de ${propertySelected.information.features.antiquy.years}`}</Timeline.Item>}
                <Timeline.Item color="green"><b>Área total: </b>{propertySelected.information.features.totalArea} m2</Timeline.Item>
                <Timeline.Item color="green"><b>Área construida: </b>{propertySelected.information.features.totalBuilded} m2</Timeline.Item>
                <Timeline.Item color="green"><b>Anotación u Observación: </b>{propertySelected.information.features.desc} </Timeline.Item>
                <Timeline.Item color="green"><b>Precio: </b>{(propertySelected.information.price.currencyType === 1 ? "S/" : "$")} {propertySelected.information.price.price} </Timeline.Item>
                <Timeline.Item color="green"><b>Es negociable: </b>{getIsNegotiableLabel(propertySelected.information.price.isNegotiable)}</Timeline.Item>
              </Timeline>
              
          { ((propertySelected && propertySelected.assignedTo === "") && callCenterOptionSelected === 2) && <Checkbox checked={optionForAssigned !== 2 && optionForAssigned !== 0} onChange={() => onChangeOption(1)}>Cliente desea una cita</Checkbox>}
          { ((propertySelected && propertySelected.assignedTo === "") && callCenterOptionSelected === 2) && <Checkbox checked={optionForAssigned !== 1 && optionForAssigned !== 0} onChange={() => onChangeOption(2)}>Cliente no desea una cita</Checkbox>}
              {
                (propertySelected && propertySelected.assignedTo === "") &&
                (callCenterOptionSelected !== 3 && optionForAssigned === 1) &&
                <Row className="assigned-section">
                <Col>
                <h4>Asignar: </h4>
                </Col>
                <Col>
                  <Select placeholder="Selecciona un agente" value={(propertySelected && propertySelected.assignedTo) ? propertySelected.assignedTo : agentSelected ? getNameAgentByCode(agentSelected) : 'Selecciona un agente'} style={{ width: 220 }} onChange={handleAgentUI}>
                    {
                      USERS_ACTIVES && USERS_ACTIVES.map(e => {
                        if (e.role === 4)
                        return (
                          <Select.Option value={e.idUser}>{e.fullNames}</Select.Option>
                        )
                      })
                    }
                  </Select>
                  <br/>
                  <br/>
                  {agentSelected && <label>Código de agente inmobiliario: {agentSelected}</label>}
                </Col>
              </Row>
            }
            {
              (callCenterOptionSelected !== 3 && optionForAssigned === 1) &&
              <Button type="primary" shape="round" size="large" onClick={() => assignedToAgent()}>
                ASIGNAR Y CERRAR
              </Button>
            }
            {
              (callCenterOptionSelected !== 3 && optionForAssigned === 2) &&
              <Button type="primary" shape="round" size="large" onClick={() => saveToLate()}>
                GUARDAR PARA DESPUES Y CERRAR
              </Button>
            }
              </React.Fragment>
          }
        </Modal>
    </React.Fragment>
  )
}

export default CallCenterView;
