const config = {
  apiKey: "AIzaSyASkdy3qycndGuUOkwWuwIXp91CPw-YgDw",
  authDomain: "agencia-inmobiliaria.firebaseapp.com",
  projectId: "agencia-inmobiliaria",
  storageBucket: "agencia-inmobiliaria.appspot.com",
  messagingSenderId: "428939570836",
  appId: "1:428939570836:web:b1553dd4b60af4adcf8e81",
  measurementId: "G-CGRHDM4K1X"
}
export default config;
